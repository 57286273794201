<template>
  <div class="inventoryPage">

    <div class="outer-wrapper">
      <div class="container-fluid">
      <div class="row justify-content-center m-0">

        <div class="col-md-9">
          <div class="tab-design">
            <div class=" text-center mb-5">
            <img src="" alt="" class="img-fluid">
          </div>
          <div class="plan-holder-inventory">
            <div class="pl-4 pr-4 b-2 rounded">
              <div>
                <b-tabs content-class="mt-3 " pills>
                  <b-tab v-for="(wing, key) in wings" :key="key" :active="key === 0" :title="wing.name">
                    <div class="card tabInventory" v-for="(flats, floor, flKey) in groupByFloor(wing.flats)"
                      :key="flKey">
                      <div class="card-body">
                        <span class="floor-title"> {{ floor }} </span>
                        <div class="row">
                          <div class="col-md-2" v-for="(flat, fKey) in flats" :key="fKey">
                            <a  class="flat-grid-holder"
                          @click="flat.status === 'Available' && selectFlat(flat, wing)" href="javascript:void(0)">
                          <div class="flat-grid" :class="getFlatClass(flat.status)"
                            v-b-popover.hover.top="availText(flat)">
                            <p class="inner-text">
                              <span class="status">Booked</span>
                              <strong class>{{ flat.name }}</strong>
                            </p>
                          </div>
                        </a>
                          </div>
                       
                        </div>
                       
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
          </div>
  
        </div>

        <div class="ct-col col-md-3 pr-0">
          <div class="side-menu next typology">
            <h3 class="headline">Selection</h3>
            <div class="form-wrapper">
            <div class="row ">
                  <div class="col-md-6 form-group">
                    <label>Typology</label>
                    <span class="sub-head">{{ selectedTypology.value }}</span>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Floor Range</label>
                    <span class="sub-head">{{ selectedFloor.value }}</span>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Saleable Area</label>
                    <span class="sub-head">{{ selectedTypology.area }} Sq.Ft.</span>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Price</label>
                    <span class="sub-head">₹ {{ selectedTypology.priceRange }} Cr</span>
                  </div>
                </div>
              </div>

            <div class="side-action">
              <button type="button" class="prevButton">
                <img src="@/assets/images/back-arrow.svg" alt="">
                Back</button>
              <p>*Price includes GST, Stamp Duty, Registration charges. It does not include Maintenance.</p>
            </div>
          </div>


        </div>
      </div>
    </div>
    </div>

    <DetailModal :selectedWing="selectedWing" :selectedFlat="selectedFlat" />
    <div class="footer-bg">
      <div class="inner-footer">
        <div class="container">
          <div class="row">

            <div class="col-md-4">
              <div class="card-foot">
                <div class="card-footer-head">
                  <span>Explore</span>
                  <h5>Project Images</h5>
                </div>
                <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" controls img-width="1024"
                  img-height="480">
                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=10"></b-carousel-slide>
                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=12"></b-carousel-slide>
                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=22"></b-carousel-slide>
                </b-carousel>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-foot">
                <div class="card-footer-head">
                  <span>Watch</span>
                  <h5>Location AV</h5>
                  <div class="location-bg">
                    <a @click="$bvModal.show('modal-virtual-visit')" href="javascript:void(0)" class="link-btn">
                      <i class="icon-play mr-2" style>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_b_52_1438)">
                            <circle cx="20" cy="20" r="16.3636" fill="url(#paint0_linear_52_1438)" fill-opacity="0.5" />
                          </g>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M37.5822 20.0599C37.5822 10.361 29.7196 2.49939 20.0217 2.49939C10.3238 2.49939 2.46008 10.361 2.46008 20.0599C2.46008 29.7589 10.3227 37.6205 20.0206 37.6205C29.7185 37.6205 37.5822 29.7589 37.5822 20.0599ZM34.6842 20.0599C34.6842 28.1581 28.1187 34.7235 20.0206 34.7235C11.9225 34.7235 5.35809 28.1581 5.35809 20.0599C5.35809 11.9618 11.9225 5.39637 20.0206 5.39637C28.1187 5.39637 34.6842 11.9618 34.6842 20.0599Z"
                            fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M25.4545 20L16.9696 13.3334V26.6667L25.4545 20Z" fill="white" />
                          <defs>
                            <filter id="filter0_b_52_1438" x="1.63635" y="1.63635" width="36.7273" height="36.7273"
                              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_52_1438" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_52_1438"
                                result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_52_1438" x1="20" y1="3.63635" x2="20" y2="36.3636"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="white" />
                              <stop offset="1" stop-color="white" stop-opacity="0" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </i>
                    </a>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-4">
              <div class="card-foot">
                <div class="card-footer-head">
                  <span>Watch</span>
                  <h5>Walkthrough</h5>
                  <div class="location-bg walkthrough-bg">
                    <a @click="$bvModal.show('modal-arial-view')" href="javascript:void(0)" class="link-btn">
                      <i class="icon-play mr-2" style>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_b_52_1438)">
                            <circle cx="20" cy="20" r="16.3636" fill="url(#paint0_linear_52_1438)" fill-opacity="0.5" />
                          </g>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M37.5822 20.0599C37.5822 10.361 29.7196 2.49939 20.0217 2.49939C10.3238 2.49939 2.46008 10.361 2.46008 20.0599C2.46008 29.7589 10.3227 37.6205 20.0206 37.6205C29.7185 37.6205 37.5822 29.7589 37.5822 20.0599ZM34.6842 20.0599C34.6842 28.1581 28.1187 34.7235 20.0206 34.7235C11.9225 34.7235 5.35809 28.1581 5.35809 20.0599C5.35809 11.9618 11.9225 5.39637 20.0206 5.39637C28.1187 5.39637 34.6842 11.9618 34.6842 20.0599Z"
                            fill="white" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M25.4545 20L16.9696 13.3334V26.6667L25.4545 20Z" fill="white" />
                          <defs>
                            <filter id="filter0_b_52_1438" x="1.63635" y="1.63635" width="36.7273" height="36.7273"
                              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix" />
                              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
                              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_52_1438" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_52_1438"
                                result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_52_1438" x1="20" y1="3.63635" x2="20" y2="36.3636"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="white" />
                              <stop offset="1" stop-color="white" stop-opacity="0" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </i>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { masterJson, projectName } from "@/assets/scripts/utility.js";
import DetailModal from '@/components/Inventory/Details.vue';
export default {
  components: {
    DetailModal,
  },
  data() {
    return {
      selectedWing: {},
      selectedFlat: {}
    }
  },
  created() {
    let selectedFloor = masterJson.Floors.find(
      (k) => k.key === this.$route.params.floor
    );
    if (selectedFloor) {
      this.$store.dispatch("setFloor", selectedFloor);
    }
    let selectedTypology = masterJson.Typologies.find(
      (k) => k.key === this.$route.params.typology
    );
    if (selectedTypology) {
      this.$store.dispatch("setTypology", selectedTypology);
    }
  },
  methods: {
    goToPlans() {
      this.$router.push({ name: 'Plans', params: { typology: this.selectedTypology.key, floor: this.selectedFloor.key } })
    },
    groupByFloor(flats) {
      return this.$_.groupBy(flats, 'floor')
    },
    availText(flat) {
      if (flat.status === "Available") {
        return "Click To Book!"
      } else if (flat.status === "Booked") {
        return "Flat Booked"
      } else if (flat.status === "Blocked") {
        return "Flat Blocked"
      }
    },
    getFlatClass(status) {
      if (status === 'Available') {
        return 'flat-available';
      } else if (status === 'Booked') {
        return 'flat-booked';
      } else if (status === 'Blocked') {
        return 'flat-blocked';
      }
    },
    selectFlat(flat, wing) {
      if (flat.status === "Available") {
        this.selectedFlat = flat
        this.selectedWing = wing
        this.$bvModal.show('modal-inventory-details')
      }
    }
  },
  computed: {
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    projectName() {
      return projectName;
    },
    wings() {
      return masterJson.Wings
    }
  },
};
</script>
<style scoped>
.outer-wrapper {
  margin: 0;
  padding-top: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 11.68%, #10020F 25.64%);

}

.plan-holder {
  border-radius: 10px;
  height: 650px;
  padding: 0;
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu {
  min-height: 650px;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu.typology {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
  z-index: 3;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.prevButton {
  color: #082D57;
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}

.form-wrapper {
  padding: 0 20px;
}

.form-wrapper label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}

.form-wrapper select {
  background: url(../assets/images/caret-icon.svg) no-repeat right .75rem center #FFFFFF;
  border: 1px solid #E7EBF0;
  border-radius: 3px;
  padding: 10px;
  appearance: none;
  height: auto;
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}

.sub-head {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
  display: block;
}

.card-foot {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.card-footer-head span {
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
}

h5 {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}

.link-btn {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.tab-design {
  max-width: 80%;
  margin: auto;
}

.tab-content > .tab-pane {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
border-radius: 4px 4px 0 0;
  border: 1px solid rgb(255 255 255 / 36%);
  position: relative;
  border-bottom: none;

}
.tab-content > .tab-pane::before{
  content: '';
  width: 100%;
  position: absolute;
  background: #564C56;
  height: 10px;
}
.floor-title {
  color: #fff;
  margin-bottom: 15px;
  display: block;
}
</style>